<template>
  <layout>
    <template>
      <div class="common-header">
        <div class="common-back" @click="back"></div>
        <div class="common-title">
          {{ $route.meta.documentTitle }}
        </div>
        <div class="common-menu" style="opacity: 0;">
          <span></span>
        </div>
      </div>
    </template>
    <div class="page-container">
      <div class="status-block">
        <div class="title">{{ orderStatusMap[orderDetailInfo.transTab] }}</div>
        <div v-if="(['CANCEL', 'SYS_CANCEL'].includes(orderDetailInfo.transTab) && orderDetailInfo.cancelCommon)" class="tip">
          取消原因：{{ orderDetailInfo.cancelCommon }}
        </div>
        <div v-if="orderDetailInfo.transTab === 'RECEIVE'" class="tip">
          暂无单号
        </div>
        <div v-if="['GOTING', 'GOT', 'TRANS', 'SIGNED'].includes(orderDetailInfo.transTab)" class="tip">
          快递单号：{{ orderDetailInfo.kuaidiNum }}
          <span class="copy-text" @click="copy">复制</span>
          <div class="copy-txt" style="position: fixed;top:-9999px;left: -9999px;">{{orderDetailInfo.kuaidiNum}}</div>
        </div>
      </div>
      <div class="info-block">
        <div class="top-info">
          <img :src="orderDetailInfo.logo" class="img-com" />
          <div class="com-info">
            <div class="name">{{ orderDetailInfo.name }}{{ orderDetailInfo.courierName ? `-${orderDetailInfo.courierName}` : '' }}</span></div>
            <!-- <div class="addr">广东省深圳市宝安区福永街道快递100</div> -->
          </div>
          <a v-if="orderDetailInfo.courierPhone" :href="`tel:${orderDetailInfo.courierPhone}`" class="phone-block" />
          <!-- <img src="https://cdn.kuaidi100.com/images/m/sntInvite/icon-phone.png" class="icon-phone" /> -->
        </div>
        <div class="detail-info">
          <div class="info-item">
            <div class="info-label">寄件信息：</div>
            <div class="info-value">
              <div class="top">
                {{ orderDetailInfo.sendName }} {{ orderDetailInfo.sendPhone }}
                <!-- <img src="https://cdn.kuaidi100.com/images/m/sntInvite/icon-lock.png" class="icon-lock" /> -->
              </div>
              <div>{{ orderDetailInfo.sendxzq }} {{ orderDetailInfo.sendaddr }}</div>
            </div>
          </div>
          <div class="info-item">
            <div class="info-label">收件信息：</div>
            <div class="info-value">
              <div class="top">
                {{ orderDetailInfo.recName }} {{ orderDetailInfo.recPhone }}
                <!-- <img src="https://cdn.kuaidi100.com/images/m/sntInvite/icon-lock.png" class="icon-lock" /> -->
              </div>
              <div>{{ orderDetailInfo.recxzq }} {{ orderDetailInfo.recaddr }}</div>
            </div>
          </div>
          <div class="info-item">
            <div class="info-label">快递公司：</div>
            <div class="info-value">{{ orderDetailInfo.shortName }}</div>
          </div>
          <div class="info-item">
            <div class="info-label">付款方式：</div>
            <div class="info-value">商家支付</div>
          </div>
          <div class="info-item">
            <div class="info-label">物品名称：</div>
            <div class="info-value">{{ orderDetailInfo.cargo }} {{ orderDetailInfo.weight ? `${orderDetailInfo.weight}kg` : '' }}</div>
          </div>
          <div class="info-item" v-if="orderDetailInfo.kuaiPhone">
            <div class="info-label">快递员：</div>
            <div class="info-value">{{ orderDetailInfo.kuaiPhone }}</div>
          </div>
          <div class="info-item">
            <div class="info-label">备注：</div>
            <div class="info-value">{{ orderDetailInfo.comment ? orderDetailInfo.comment : '' }}</div>
          </div>
        </div>
      </div>
        <div v-if="orderDetailInfo.transTab=='CHECK_WAIT'" class="cancel-btn" @click="cancelOrder">
          取消订单
        </div>
    </div>
  </layout>
</template>
<script>
export default {
  name: "sntInvite",
  data() {
    return {
      sign: "",
      expId: "",
      orderDetailInfo: {},
      orderStatusMap: {
        WAIT: "待下单",
        ORDERING: "下单中（临时状态）",
        SENT_SUCES: "下单成功",
        GOT_FAIL: "揽货失败",
        RETURN: "退签",
        EXCEPTION: "签收异常",
        SENT_SUCESS: "待快递员接单",
        CHECK_WAIT: "订单待商家审核", // 待审核
        CANCEL: "您的订单已取消", // 用户取消取件
        SYS_CANCEL: "您的订单已取消", // 系统取消
        RECEIVE: "待快递员接单", // 接单
        GOTING: "待快递员取件", // 取件中
        GOT: "快递员已取件", // 取件
        TRANS: "在途中", // 在途
        SIGNED: "已签收", // 签收
      },
      type: "",
    };
  },
  methods: {
    back() {
      location.replace(
        `${location.origin}/mexpress/sntinvite/orderlist?sign=${this.sign}&type=${this.type}`
      );
    },
    copy(str) {
      // 复制
      const range = document.createRange();
      range.selectNode(document.querySelector(".copy-txt"));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("Copy");
      this.$toast("复制成功");
    },
    getcookie(cookieName) {
      let cookieValue = "";
      if (document.cookie && document.cookie != "") {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].replace(/(^\s*)|(\s*$)/g, "");
          if (cookie.substring(0, cookieName.length + 1) == cookieName + "=") {
            cookieValue = unescape(cookie.substring(cookieName.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    getOrderDetail() {
      const { sign, expId } = this;
      this.$http
        .post("/snt/order/open/exclusiveVisit.do", {
          data: {
            type: "SNTSENTNATIVE",
            expId,
            sign,
            // token: this.getcookie('TOKEN')
          },
        })
        .then((res) => {
          this.orderDetailInfo = {
            ...res,
            recxzq: res.recxzq.replace(/\#/g, ""),
            sendxzq: res.sendxzq.replace(/\#/g, ""),
            ...jsoncom.company.filter((com) => {
              return com.number === res.kuaidicom;
            })[0],
            logo: `https://cdn.kuaidi100.com/images/all/56/${res.kuaidicom}.png`,
            courierName: res.kuaiPhone ? res.kuaiPhone.split(":")[0] : "",
            courierPhone: res.kuaiPhone ? res.kuaiPhone.split(":")[1] : "",
          };
        });
    },
    cancelOrder(){
      const { sign, expId } = this;
      this.$http
        .post("/snt/order/open/exclusiveVisit.do?type=SNTNVITEDELEXPRESS", {
          data: {
            expId,
            sign,
          },
        })
        .then((res) => {
          if(res.status==200){
            this.$toast("取消成功");
            this.back()
          }
        });
      
    }
  },
  activated() {
    const { sign, expId, type } = this.$route.query;
    this.sign = sign;
    this.expId = expId;
    this.type = type;
    this.login(() => {
      this.getOrderDetail();
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "login" && vm.$store.state.globalData.token) {
        vm.loginCallback();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@mixin paddingToRem($top, $right, $bottom, $left) {
  padding: $top * 1rem $right * 1rem $bottom * 1rem $left * 1rem;
}

@mixin borderRadiusToRem(
  $topLeft: 0,
  $topRight: 0,
  $bottomRight: 0,
  $bottomLeft: 0
) {
  border-radius: $topLeft * 1rem $topRight * 1rem $bottomRight * 1rem
    $bottomLeft * 1rem;
}

@mixin flexLayout($justify: center, $align: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin font($size, $color, $lineHeight, $weight: normal) {
  font-family: "PingFangSC-Medium";
  font-size: $size * 1rem;
  font-weight: $weight;
  line-height: $lineHeight * 1rem;
  color: $color;
  letter-spacing: 0px;
}

.page-container {
  @include flexLayout(flex-start, center, column);
  padding-top: 0.625rem;
  background: #f7f7f7;
  .status-block {
    width: 21.5625rem;
    @include paddingToRem(0.9375, 0, 0.9375, 0);
    background: #fff;
    border-radius: 0.3125rem;
    .title {
      @include font(1.1875, #3d3d3d, 1.3125);
      text-align: center;
    }
    .tip {
      margin-top: 0.5625rem;
      @include font(0.875, #7f8c98, 0.875);
      text-align: center;
      .copy-text {
        color: #0a84ff;
      }
    }
  }
  .info-block {
    width: 21.5625rem;
    margin-top: 0.75rem;
    background: #fff;
    .top-info {
      position: relative;
      @include flexLayout;
      @include paddingToRem(0.75, 0.5625, 1.34375, 0.625);
      @include borderRadiusToRem(0.3125, 0.3125, -0.3125, -0.3125);
      border-bottom: 1px dashed #d8d8d8;
      &::before {
        position: absolute;
        left: -0.375rem;
        bottom: -0.375rem;
        content: "";
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        background: #f7f7f7;
        border-radius: 50%;
      }
      &::after {
        position: absolute;
        right: -0.375rem;
        bottom: -0.375rem;
        content: "";
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        background: #f7f7f7;
        border-radius: 50%;
      }
      .img-com {
        width: 2.6875rem;
        height: 2.6875rem;
        margin-right: 0.5625rem;
        border-radius: 50%;
      }
      .com-info {
        flex: 1;
        .name {
          @include font(0.9375, #001a32, 1.25);
        }
        .addr {
          @include font(0.8125, #b2bac1, 0.9375);
        }
      }
      .icon-phone {
        width: 1.875rem;
        height: 1.875rem;
      }
      .phone-block {
        width: 1.875rem;
        height: 1.875rem;
        background: url("https://cdn.kuaidi100.com/images/m/sntInvite/icon-phone.png");
        background-size: cover;
      }
    }
    .detail-info {
      @include paddingToRem(0, 0.375, 2, 0.75);
      .info-item {
        margin-top: 1.25rem;
        @include flexLayout(flex-start, flex-start);
        .info-label {
          flex-shrink: 0;
          @include font(0.875, #7f8c98, 1.1875);
        }
        .info-value {
          @include font(0.875, #001a32, 1.1875);
        }
        .icon-lock {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
    }
  }
  .cancel-btn {
    background: #0082fa;
    font-size: 0.875rem;
    color: #fff;
    padding: 0.6rem 1.5rem;
    border-radius: 0.3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>